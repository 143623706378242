const data = [
  "https://chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure1.png",
  "https://chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure2.png",
  "https://chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure3.png",
  "https://chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure4.png",
  "https://chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure5.png",
  "https://chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure6.png",
  "https://chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure7.png",
  "https://chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure8.png",
  "https://chinmayavvdelhi.ac.in/images/Blackele/infrastructure81.jpg",
  "https://chinmayavvdelhi.ac.in/images/Blackele/infrastructure82.jpg",
  "https://chinmayavvdelhi.ac.in/images/Blackele/infrastructure83.jpg",
  "https://chinmayavvdelhi.ac.in/images/Blackele/infrastructure84.jpg",
  "https://chinmayavvdelhi.ac.in/images/Blackele/infrastructure85.jpg",
  "https://chinmayavvdelhi.ac.in/images/Blackele/infrastructure86.jpg",
  "https://chinmayavvdelhi.ac.in/images/Blackele/infrastructure87.jpg",
  "https://chinmayavvdelhi.ac.in/images/Blackele/infrastructure88.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/infrastructure-3.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/infrastructure-2.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/infrastructure-1.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/pic13.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/pic12.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/pic11.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/pic10.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/pic9.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/pic8.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/pic7.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/pic6.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/pic5.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/pic4.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/pic3.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/pic2.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/infrastructure/pic1.jpeg",
];

export default data.reverse();
